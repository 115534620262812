@import './variables.scss';
@import './_mixins.scss';

.hc-topbar-div {
    @include contentEnd;
    padding: 0 20px;

    .notify-bell {
        padding: 4px 10px;

        .fa {
            font-size: 22px;
            color: $menuTextColor;

            &.active {
                color: $heighLightText;
            }
        }
    }

    .profile-dropdown {
        padding: 4px 0 4px 10px;
        min-width: 32%;

        .profile-btn {
            width: 100%;

            button {
                display: flex;
                align-items: center;
                background-color: $white;
                color: $textColor;
                border-color: $white;
                width: 100%;

                &::after {
                    margin-left: 2rem;
                    border-top: 0.5em solid !important;
                    border-right: 0.5em solid transparent !important;
                    border-bottom: 0 !important;
                    border-left: 0.5em solid transparent !important;
                }

                &:focus {
                    box-shadow: none;
                }

                .btn-title {
                    display: flex;
                    align-items: center;
                    padding: 5px;

                    .prof-img {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }

                    .profile-name {
                        padding: 0 10px;
                        text-align: left;

                        h6 {
                            margin-bottom: 0.12rem;
                            font-weight: 600;
                        }
                    }
                }
            }

            .dropdown-menu {
                width: 100%;
                border: none;
            }
        }
    }
}
.logout-text{
    text-align: center;
    padding: 10px;
    .input-div{
        text-align: center;
    }
}

@media only screen and (max-width: 1366px) {
    .hc-topbar-div {
        padding: 0 10px !important;
        .profile-dropdown .profile-btn button .btn-title {
            img {
                width: 40px !important;
                height: 40px !important;
            }
            .profile-name {
    
                h6 {
                    font-size: 0.95rem !important;
                }
                p {
                    font-size: 0.75rem !important;
                }
            }
        }
    }

    .dropdown-item {
        font-size:  13px !important;
    }
}
@media only screen and (max-width: 1200px) {
    .hc-topbar-div {
        padding: 0 5px !important;
        .profile-dropdown .profile-btn button::after {
            margin-left: 0rem !important;
        }
    }
}