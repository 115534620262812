@import "variables";
@import "mixins";

#header {
  position: relative;
  transition: width 0.5s ease, opacity 0.5s ease;
  height: 100%;

  &.is_open {
    transition: width, right, left, 0.3s;
    width: 16%;
    min-width: 16%;
  }

  &.is_close {
    transition: width, right, left, 0.3s;
    width: 100px;
    min-width: 100px;
  }

  .hc-sidebar-header {
    border: none;
  }

  .pro-sidebar {
    height: 100%;
    width: 100%;
    min-width: 100%;

    &.collapsed {
      width: 100%;
      ;
      min-width: 100%;
      ;

      .pro-menu .pro-menu-item {
        .pro-inner-item {
          transition: padding 0.3s ease;
          padding: 8px 35px 8px 12px !important;
        }
      }
    }
  }

  .closemenu {
    color: #000;
    position: absolute;
    right: -20px;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 20px;
    top: 30px;
    cursor: pointer;
  }

  .pro-sidebar-inner {
    background-color: white;
    border-top-right-radius: 20px;

    .pro-sidebar-layout {
      overflow-y: hidden;
      position: initial;

      .logotext {
        @include contentStart;
        padding: 30px 40px 30px 20px;
        transition: padding 0.3s ease;

        img {
          width: 260px;
          height: 75px;
        }
      }

      ul {
        padding: 0 20px;

        .pro-inner-item {
          transition: padding 0.3s ease;
          color: $menuTextColor;
          margin: 10px 0px;
          font-weight: bold;
          font-size: 16px !important;

          .pro-icon-wrapper {
            background-color: transparent;
            color: $menuTextColor;
            border-radius: 3px;

            .pro-item-content {
              color: $menuTextColor;
              font-size: 16px !important;
            }
          }
        }
      }

      .active {
        // background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
        background: $butonBackGround;
        border-radius: 20px;

        .pro-item-content {
          color: $heighLightText;
        }
      }
    }
  }

  .logo {
    padding: 20px;
  }
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
@media only screen and (max-width: 1600px) {
  #header {
    &.is_open {
      width: 18% !important;
      min-width: 18% !important;
    }
  }
}
@media only screen and (max-width: 1440px) {
  #header {
    &.is_open {
      width: 19% !important;
      min-width: 19% !important;
    }
  }
}
@media only screen and (max-width: 1366px) {
  #header .pro-sidebar-inner .pro-sidebar-layout {
    .logotext {

      img {
        width: 160px !important;
        height: 50px !important;
      }  
    }
    ul {
      .pro-inner-item {
        font-size: 14px !important;
        .pro-icon-wrapper {
          height: 25px !important;
        }
      }
    }
  }
  #header {
  
    &.is_open {
      width: 20% !important;
      min-width: 20% !important;
    }
  }
  
}