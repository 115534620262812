@import "variables";
@import "mixins";
@import 'login';
@import 'sideBar';
@import 'unspsc';
@import './topbar.scss';
@import './subscription.scss';
@import './snomedsearcher.scss';
@import './changePassword.scss';

.txt-input {
    border-color: $borderColor;
    border-radius: 10px;
    height: 40px;

    &:focus {
        border-color: $borderColor;
        outline: none !important;
        box-shadow: none !important;
    }

    .react-select-list__control {
        height: 40px;
        border-color: $borderColor !important;
        border-radius: 10px;
        font-size: 13px;
        font-weight: 600;
        overflow: auto !important;
    }

    .react-select-list__menu {
        z-index: 9999;
    }
}

.form-label {
    font-size: 13px;
    color: $menuTextColor;
    margin-bottom: 0.15rem
}

.error-msg {
    font-size: 11px;
    color: $warningColor;
}

.table-drobdown-btn{
    border: none;
    &:focus {
        box-shadow: none;
    }
    &::after {
        display: none;
    }
}

.selected-row {
    background-color: $classBgColor;
    td{
        background-color: $classBgColor !important;
        // color: $white !important;
    }
}

.hc-submit-btn {
    @include contentCenter;
    padding: 8px 25px;
    background-color: $butonBackGround;
    color: $heighLightText;
    font-weight: 600;
    font-size: 16px;
    border-color: $butonBackGround;
    border-radius: 40px;

    &:hover,
    &:focus,
    &.active,
    &:focus-visible {
        border-color: $butonBackGround !important;
        color: $heighLightText !important;
        background-color: $butonBackGround !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.input-div {
    position: relative;
    text-align: left;
    padding: 5px;

    .login-form-lable {
        font-weight: 700;
    }
}
.pswd-hide-show {
    position: absolute;
    right: 1.1rem;
    bottom: 1rem;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.hc-toggle-false-btn {
    @include contentCenter;
    height: 40px;
    padding: 8px 12px;
    width: 70px;
    background-color: $butonBackGround;
    color: $heighLightText;
    font-weight: 600;
    font-size: 16px;
    border-color: $butonBackGround;
    border-radius: 10px;

    &:hover,
    &:focus,
    &.active,
    &:focus-visible {
        border-color: $butonBackGround !important;
        color: $heighLightText !important;
        background-color: $butonBackGround !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.hc-toggle-true-btn {
    @include contentCenter;
    height: 40px;
    width: 70px;
    background-color: $primaryColor;
    color: $white;
    font-weight: 600;
    font-size: 16px;
    border-color: $primaryColor;
    border-radius: 10px;

    &:hover,
    &:focus,
    &.active,
    &:focus-visible {
        border-color: $primaryColor !important;
        color: $white !important;
        background-color: $primaryColor !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.search-btn {
    width: 100%;
    min-width: 40px;
    height: 40px !important;
    border-radius: 10px !important;
    padding: 0 !important;
}

.row {
    margin: 0 !important;
}

// main content div 
.main-conent-div {
    width: 100%;
    padding: 15px 30px 15px;
    overflow: auto;

    .main-div-row {
        @include contentCenter;
        min-height: 8%;

        .page-header-div {
            @include contentStart;
            padding: 10px 20px;

            .hc-main-header {
                // @include contentStart;
                font-size: 20px;
                color: $textColor;
                font-weight: 600;
                line-height: 33px;

                span {
                    padding: 0 10px 4px;
                }
            }
        }

        .page-header-btn-div {
            @include contentBetween;
            padding: 10px 5px;

            .hc-main-header {
                // @include contentStart;
                font-size: 20px;
                color: $textColor;
                font-weight: 600;
                line-height: 33px;

                span {
                    padding: 0 10px 4px;
                }
            }
        }
    }
}

.filter-lable {
    color: $textColor;
    font-weight: 600;
    padding: 0;
}

.latest-version-lable {
    color: $versionColor;
    font-weight: 600;
    animation: blink 1.2s linear infinite;
}

@keyframes blink {
    /* From and to could be 0% and 100% respectively */
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

.table-section {
    height: 100%;
    width: 100%;

    .custom-table {
        height: 100%;
        max-height: 100% !important;
        min-height: 265px !important;
        width: 100%;
        background-color: $white;
        border-radius: 20px;
        border: none !important;
        box-shadow: none !important;

        thead {
            background-color: $tableHeaderBackground !important;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            th {
                text-wrap: nowrap !important;
                color: $textColor;
                font-size: 14px;
                font-weight: 700;
                padding: 4px !important;
                background-color: $tableHeaderBackground !important;
                border-bottom: 1px solid $tableHeaderBackground;
            }
        }

        tbody {
            tr {
                border: none;

                &:nth-child(odd) {
                    background-color: $white;
                }

                &:nth-child(even) {
                    background-color: $backGround;
                }

                td {
                    font-size: 13px;
                    border-bottom: none;
                    padding: 4px !important;
                    text-wrap: nowrap;
                }
            }
        }
    }
}

.custom-table::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}

.custom-table::-webkit-scrollbar-track {
    background: $menuTextColor;
    border-radius: 4px;
}

.custom-table::-webkit-scrollbar-thumb {
    background: $primaryColor;
    border-radius: 4px;
}

.custom-table::-webkit-scrollbar-thumb:hover {
    background: $heighLightText;
}

.custom-pagination-div {
    p {
        margin: 0 !important;
    }
}

.filter-clear-btn {
    background-color: $white;
    border-radius: 4px;
    padding: 10px;
}

.filter-section {
    padding: 5px;

    .filter-div {
        background-color: $white;
        border-radius: 10px;
        padding: 10px;
        height: 100%;

        .row {
            @include contentBaseline;
            margin: 0;
            padding-bottom: 20px;

            .version-lable-div {
                @include contentBetween;
            }
        }

        .code-filter {
            @include contentBetween;
        }

        .code-input {
            width: 90% !important;
        }
    }
}

.access-deneid-page {
    @include contentCenter;
    height: 100%;
    width: 100%;
}
.loader-container {
    z-index: 99 !important;
}
.table-div, .unspsc-table-section {
    animation: 1s slide-right;
  }
  @keyframes slide-right {
    0% {
        opacity:0;
      }
      100% {
        opacity:1;
      }
  }
@media only screen and (max-width: 1366px) {
 .main-conent-div {
    padding: 10px 10px 8px !important;
    .main-div-row { 
        .page-header-btn-div .hc-main-header, .page-header-div .hc-main-header {
            font-size: 16px !important;
        }
    }
 }
 .hc-submit-btn {
    padding: 4px 12px !important;
    font-size: 14px !important;
 }
 .hc-toggle-false-btn, .hc-toggle-true-btn {
    height: 30px !important;
    font-size: 14px !important;
 }
 .txt-input {
    height: 30px;
    font-size: 0.75rem !important;
    .react-select-list__control {
        height: 30px !important;
        min-height: 30px !important;
        .react-select-list__indicators {
            height: 30px !important;
        }
    }
 }
 .latest-version-lable {
    font-size: 11px !important;
    }
    .search-btn {
        min-width: 30px;
        height: 30px !important;
    }

    .custom-table {
        thead {
            th {
                font-size: 12px !important;
            }
        }

        tbody {
            tr {
                td {
                    font-size: 0.75rem !important;
                }
            }
        }
    }
    .form-control, .code-details-div .code-display-section .category-section .category-txt-div  {
        font-size: 0.75rem !important;
    }
}