@import "variables";

@mixin contentCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin contentStart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@mixin contentEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@mixin contentBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin contentAround {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@mixin contentBaseline {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}