@import "variables";
@import 'mixins';

.result-display-section {
    background-color: $white;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
    @include contentBaseline;

    .result-display-div {
        width: 100%;

        h6 {
            margin: 0;
        }

        .result-code-txt-div {
            height: 25%;
            padding: 10px 3px;

            .txt-dipsplay {
                width: 100%;
                position: relative;
            }

            .copy-icon {
                position: absolute;
                top: 5px;
                right: 10px;
            }

            .resulttxt {
                background-color: $white;
            }

        }
    }
}

.table-div {
    padding: 10px 5px;

    .snomed-table-section{
        height: 260px
    }

    .table-header-filter {
        @include contentBetween;
        padding: 5px;

        .search-input {
            width: 100%;
            position: relative;

            .searchTxt {
                width: 40%;
                float: right;
                border-color: $white !important;
                transition: width 0.3s ease;

                &:focus {
                    width: 50% !important;
                }
            }
        }

        .copy-icon {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }
}
.note-text{
    font-size: 12px;
    padding-top: 10px;
    margin: 0 !important;
    color: $warningColor;
}

@media only screen and (max-width: 1366px) {
    .table-div .table-header-filter .copy-icon {
        height: 30px !important;
        .hc-submit-btn{
            padding: 4px 8px !important;
            height: 30px !important;
        }
    }
}