@import "variables";
@import "mixins";

.hc-login-row {
    height: 100%;
    margin: 0;

    .hc-login-left-col {
        background-image: $gradient-background;
        position: relative;
        // padding: 6% 7%;
        height: 100%;
        @include contentCenter;

        .login-page-img-div {
            background-color: $primaryLight;
            border-radius: 40px;
            width: 80%;
            height: 80%;
            z-index: 2;
            position: relative;
            padding: 45px;

            .login-overflow1 {
                position: absolute;
                right: -8%;
                top: 12%;
                width: 15%;
            }

            .login-display-text {
                text-align: left;
                color: $white;
                padding-right: 25%;

                h3 {
                    font-size: 2.625rem;
                }
            }

            .login-overflow2 {
                position: absolute;
                left: -8%;
                bottom: 12%;
                width: 15%;
            }

            .image-banner {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-top: 12%;
                position: relative;

                .banner-img {
                    width: 70%;
                }

                .verticle-devider {
                    position: absolute;
                    left: 0;
                    top: 30%;
                    height: 30%;
                    width: 2px;
                    background-color: $white;
                }

                .horizontal-devider {
                    position: absolute;
                    left: 30%;
                    bottom: 0;
                    height: 3px;
                    width: 30%;
                    background-color: $white;
                }
            }
        }

        .login-background {
            position: absolute;
            left: 0;
            bottom: 0;
            overflow: hidden;
            z-index: 1;
            width: 70%;
        }

        ;
    }

    .hc-login-right-col {
        position: relative;
        padding: 2% 3%;

        .hc-logo-div {
            padding: 5px;
            @include contentEnd;
        }

        .login-form-div {
            @include contentCenter;
            flex-direction: column;
            padding: 15% 8%;

            .greeting-text {
                font-size: 2rem;
                font-weight: 600;
            }

            .login-sub-text {
                font-weight: 600;
            }

            .login-form {
                padding: 8% 5px;
                width: 100%;

                .input-div {
                    position: relative;
                    text-align: left;
                    padding: 5px;

                    .login-form-lable {
                        font-weight: 700;
                    }
                }

                .forgot-link {
                    padding: 5px;
                    @include contentEnd;

                    h4 {
                        font-size: 13px;
                        color: $warningColor;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .login-btn-div {
                    @include contentCenter;
                    padding: 30px;

                    .hc-submit-btn {
                        width: 40%;
                    }
                }
            }

            .create-link {
                color: $heighLightText;
                cursor: pointer;
            }
        }
    }
}

.reset-form {
    padding: 10px 20px;
}

.btn-submit-cancel {
    @include contentCenter;
    padding: 15px;

    .btn-save,
    .btn-cancel {
        padding: 5px;
    }
}

@media screen and (max-width: 1550px) {
    .login-display-text {
        h3 {
            font-size: 1.625rem !important;
        }
    }

    // .hc-login-row .hc-login-right-col .login-form-div {
    //     padding: 5% 8% !important;
    // }
}

@media screen and (max-width: 1366px) {
    .login-display-text {
        h3 {
            font-size: 1.325rem !important;
        }
    }
    .login-form-div { 
        padding: 5% 8% !important;
    }
}