@import "variables";
@import 'mixins';

.unspsc-table-section {
    padding: 10px 5px;
    height: 58vh;
}

.code-details-div {
    height: 100%;
    padding: 5px;

    .code-display-section {
        background-color: $white;
        border-radius: 10px;
        padding: 10px;
        height: 100%;

        .segment-section {
            min-height: max-content;
            padding-top: 10px;
            padding-bottom: 20px;

            .segment-code-txt-div {
                height: 25%;
                padding: 10px 3px;
                @include contentStart;

                .form-check-inline {
                    margin-right: 0
                }

                .pr {
                    padding-right: 5px;
                    width: 30%;
                    position: relative;
                }

                .txt-dipsplay {
                    width: 70%;
                    position: relative;
                }

                .full-txt-dipsplay {
                    width: 100%;
                    position: relative;
                }

                .copy-icon {
                    position: absolute;
                    top: 5px;
                    right: 10px;
                    height: 20px;
                    width: 20px;
                    img {
                        width: 100%;
                    }
                }

                .segment1 {
                    background-color: $segmentBgColor !important;
                    border-color: $segmentBgColor !important;
                    color: $segmentColor;
                }

                .segment2 {
                    background-color: $familyBgColor !important;
                    border-color: $familyBgColor !important;
                    color: $familyColor;
                }

                .segment3 {
                    background-color: $classBgColor !important;
                    border-color: $classBgColor !important;
                    color: $classColor;
                }

                .segment4 {
                    background-color: $comodityBgColor !important;
                    border-color: $comodityBgColor !important;
                    color: $comodityColor;
                }

                .form-check-input[type=radio] {
                    border-color: $primaryColor !important;
                }
            }

            .segments-types {
                @include contentCenter;
                flex-wrap: wrap;
                padding: 15px 0;

                .color-box {
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    display: inline-block;

                    &.seg-box {
                        background-color: $segmentColor;
                    }

                    &.fam-box {
                        background-color: $familyColor;
                    }

                    &.cls-box {
                        background-color: $classColor;
                    }

                    &.com-box {
                        background-color: $comodityColor;
                    }
                }

                .section-txt {
                    padding: 0 10px;
                    color: $textColor;
                    font-weight: 600;
                }
            }
        }

        .category-section {
            min-height: max-content;
            padding-top: 10px;
            padding-bottom: 20px;

            .category-txt-div {
                border-radius: 10px;
                border: 1px solid $borderColor;
                min-height: 200px;
                max-height: 40vh;
                padding: 10px;
                overflow: auto;
                position: relative;

                .copy-icon {
                    position: absolute;
                    top: 5px;
                    right: 10px;
                }
            }

            .category-txt-div::-webkit-scrollbar {
                width: 8px !important;
                height: 8px !important;
            }

            .category-txt-div::-webkit-scrollbar-track {
                background: $menuTextColor;
                border-radius: 4px;
            }

            .category-txt-div::-webkit-scrollbar-thumb {
                background: $primaryColor;
                border-radius: 4px;
            }

            .category-txt-div::-webkit-scrollbar-thumb:hover {
                background: $heighLightText;
            }
        }

    }
}
.hc-pagination-section {
    @include contentBetween;
    padding: 0 10px;
}
.form-check-input[type=checkbox] {
    border-color: $primaryColor !important;
}
.form-check-input:checked {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

@media only screen and (max-width: 1366px) {
    .code-details-div .code-display-section {
        // padding: ;
        .segment-section {
            padding: 5px !important;
            .segments-types {
                padding: 3px 0 !important;
                .color-box {
                    width: 12px !important;
                    height: 12px !important;
                }
                .section-txt {
                    font-size: 0.765rem !important;
                }
            }

            .segment-code-txt-div {
                padding: 6px !important;
                .copy-icon {
                    top: 2px !important;
                    right: 10px !important;
                }
            }
        }
        .category-section {
            padding-bottom: 15px !important;
        }
    }
    .unspsc-table-section {
        height: 50vh !important;
    }
}