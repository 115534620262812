@import './variables.scss';
@import './mixins';

.subscriber-search-bar {
    @include contentBetween;
    padding: 10px 20px;
    min-height: 8%;

    .search-input-div {
        background-color: $white;
        border-radius: 4px;
        padding: 10px;

        // width: 100%;
        .form-select {
            border-color: $backGround;
            background-color: $backGround;
            min-width: max-content;
            color: $heighLightText;
            font-weight: 600;
            font-size: 16px;

            &:focus {
                border-color: $backGround;
                box-shadow: none;
            }
        }

        .form-control {
            min-width: 400px;
            border: 4px solid $backGround !important;
            font-weight: 600;
            font-size: 16px;

            &:focus {
                border-color: $backGround;
                box-shadow: none;
            }
        }
    }

    .add-subscribe-btn-div {
        background-color: $white;
        border-radius: 4px;
        padding: 10px;
    }
}

.subscription-table-div {
    @include contentCenter;
    flex-direction: column;
    padding: 10px 20px;
}

.pagination-div {
    @include contentEnd;
    padding: 10px 20px;

    h6 {
        margin: 0;
    }

    .pagination {
        display: flex;
        align-items: center;
        margin: 0;

        .page-item {
            cursor: pointer;

            .page-link {
                border: none !important;
                color: $textColor;
                font-size: 13px;
                font-weight: 600;
                background-color: $backGround;

                span {
                    font-size: 30px;
                }
            }

            &.active {
                .page-link {
                    color: $white;
                    background-color: $primaryColor;
                    border-radius: 4px;
                }
            }
        }

    }
}

.add-subscription-modal {
    .modal-dialog {
        max-width: 50% !important;

        .modal-header {
            padding: 20px;
            display: flex;
            align-items: center;
            background-color: $tableHeaderBackground;

            .modal-header-text {
                margin: 0;
                color: $textColor;
                font-weight: 700;
            }
        }

        .add-edit-subscription-form {
            padding: 20px;

            .row {
                margin: 0;

                // @include contentCenter;
                .sub-col1 {
                    padding: 0 5px 0 0;
                }

                .sub-col2 {
                    padding: 0 0 0 5px;
                }
            }

            .company-logo-image {
                @include contentStart;

                .company-img {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background-color: $butonBackGround;
                }

                .img-upload-btn-div {
                    padding: 0 15px;

                    .upload-img-btn {
                        background-color: $butonBackGround;
                        color: $heighLightText;
                        border: none;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                            border: none;
                        }
                    }
                }
            }

            .date-picker-with-icon {
                position: relative;

                .react-datepicker-wrapper {
                    width: 100%;

                    .hc-date-picker {
                        padding: 0 10px;
                        width: 100%;
                        border: 1px solid $borderColor;
                        font-size: 13px;
                    }
                }

                .calander-icon {
                    position: absolute;
                    top: 30%;
                    right: 5%;
                }
            }
        }

        .btns-save-cancel {
            @include contentCenter;
            padding: 20px;

            .btn-save,
            .btn-cancel {
                padding: 0 10px;
            }
        }
    }
}

.subscription-view {
    height: 82vh;
    background-color: $white;
    border-radius: 10px;
    padding: 60px 10px;
    .view-details-col {
        @include contentCenter;
        flex-direction: column;
        .company-logo-image{
            padding: 20px;
            .company-img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: $butonBackGround;
            }
        }
        .company-header{
            h6 {
            font-weight: 800;
            }
        }
        .detail-text {
            padding: 10px 0;
            @include contentCenter;
            flex-direction: column;
            p {
                color: $menuTextColor;
                font-size: 13px;
                margin: 0;
            }
            h6 {
                color: $textColor;
            }

            .date-view-btn{

            }
        }
    }
    .view-details-right {
        border-left: 1px solid $menuTextColor;
        @include contentCenter;
        .devider {
            width: 100%;
            height: 1px;
            border-radius: 2px;
            background-color: $menuTextColor;
        }
    }
}
.fa-chevron-left {
    cursor: pointer;
}
.table-drobdown-btn{
    padding: 0;
}

@media only screen and (max-width: 1200px) {
    .subscription-view {
        min-height: 82vh !important;
        height: auto !important;
    }
}
@media only screen and (max-width: 1366px) {
    .subscription-view{
        padding: 20px 10px !important;
        .view-details-col { 
            .company-logo-image .company-img {
                height: 80px !important;
                width: 80px !important;
            }
            .company-header{
                h6 {
                    font-size: 14px !important;
                }
            }
            .detail-text {
                padding: 4px 0 !important;
                h6 {
                    font-size: 13px !important;
                }
            }
            .date-view-btn {
                font-size: 12px !important;
                padding: 4px 6px !important;
            }
        }
    }

    .pagination-div {
        h6 {
            font-size: 11px !important;
        }
    }
    .pagination-div .pagination .page-item .page-link span {
        font-size: 20px !important;
    }
    .subscriber-search-bar .search-input-div .form-control {
        border: 1px solid $backGround !important;
    }
}