@import './variables.scss';
@import './_mixins.scss';

.change-password-form-div {
    @include contentCenter;
    padding: 10px;
    border-radius: 10px;
    background-color: $white;
    .change-password-form {
        @include contentCenter;
        padding: 5px;
        flex-direction: column;
        width: 40%;
        .form-div {
            width: 100%;
        }
        .change-btn-div {
            @include contentCenter;
        }
    }
}