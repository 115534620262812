:root {
    --primary-color: #5842bc;
    --primary-light: #B3A8FF8F;
    --primary-palelight: #b2a5ee;
    --primary-verylight: #e8e4faba;

    --gradient-background: linear-gradient(90deg, #5138EE 5%, #7e69db 50%, #8D7CF3 100%);
    --backGround: #F8F9FC;
    --white: #fff;
    --black: #000;
    --border: #D0D2DA;

    --warning: #EF3B3A;
    --butonBackGround: #F3F0FF;
    --heighLightText: #551FFF;

    --menuTextColor: #92959E;
    --textColor: #15192C;

    --tableHeaderBackground: #EAEAF6;

    --segmentColor: #ED4F35;
    --familyColor: #409E0D;
    --classColor: #2E4BA9;
    --comodityColor: #7F8789;

    --segmentBgColor: #ed4f3547;
    --familyBgColor: #409e0d3d;
    --classBgColor: #2e4ba936;
    --comodityBgColor: #7f87893b;

    --versionColor: #FFB900;
}

$primaryColor: var(--primary-color);
$primaryLight: var(--primary-light);
$primaryPlaeLight: var(--primary-palelight);
$primaryVeryLight: var(--primary-verylight);

$gradient-background: var(--gradient-background);
$backGround: var(--backGround);
$white: var(--white);
$borderColor: var(--border);

$warningColor: var(--warning);
$butonBackGround: var(--butonBackGround);
$heighLightText: var(--heighLightText);

$menuTextColor: var(--menuTextColor);
$textColor: var(--textColor);

$tableHeaderBackground: var(--tableHeaderBackground);

$segmentColor: var(--segmentColor);
$familyColor: var(--familyColor);
$classColor: var(--classColor);
$comodityColor: var(--comodityColor);

$segmentBgColor: var(--segmentBgColor);
$familyBgColor: var(--familyBgColor);
$classBgColor: var(--classBgColor);
$comodityBgColor: var(--comodityBgColor);

$versionColor: var(--versionColor)